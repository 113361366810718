import { breakpoints } from '@iheartradio/web.accomplice';
import { NavAdContainer } from '@iheartradio/web.accomplice/display-ads';
import { useFullScreenPlayer } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';
import { useDeepCompareEffect } from '@iheartradio/web.remix-shared/react/use-deep-compare-effect.js';
import { isNotBlank } from '@iheartradio/web.utilities';
import { type MouseEventHandler, memo, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'usehooks-ts';

import { playback } from '~app/playback/playback';

import { GoogleAds, useDisplayAdsContext } from './ads';
import { CompanionAd } from './companion-ad';
import { handler } from './handler';
import { useDisplayTargeting } from './targeting/use-display-targeting';
import { useAdUnit } from './use-ad-unit';
import { useDisplayAd } from './use-display-ad';

export const NAV_AD_SIZES = [
  [300, 250],
  // [300, 600], TODO: disabling until "See what's new" button goes away
] as [number, number][];

export const adClickHandler: MouseEventHandler<HTMLDivElement> = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  GoogleAds.click(event.nativeEvent);
};

export const NavAd = memo(function NavAd() {
  const { ref, inView } = useInView();

  const { isOpen: isFullScreen } = useFullScreenPlayer();

  const adUnitPath = useAdUnit();

  const playerState = playback.useState();
  const playerAdsState = playback.useAds();

  const [companion, setCompanion] = useState<JSX.Element | null>(null);

  const [displayState, setDisplayState] = useState({
    displayAd: false,
    companionAd: false,
  });

  const { display } = useDisplayAd(useMediaQuery(breakpoints.large));
  const { enabled: displayAdsEnabled } = useDisplayAdsContext();

  const targeting = useDisplayTargeting();

  useDeepCompareEffect(() => {
    if (
      display &&
      ((playerAdsState.status === Playback.AdPlayerStatus.Idle &&
        playerState.status === Playback.Status.Idle) ||
        playerAdsState.status === Playback.AdPlayerStatus.Done) &&
      isNotBlank(adUnitPath)
    ) {
      setDisplayState(previousProps => {
        return {
          ...previousProps,
          displayAd: true,
        };
      });
    } else {
      setDisplayState(previousProps => {
        return {
          ...previousProps,
          displayAd: false,
        };
      });
    }
  }, [adUnitPath, display, playerAdsState]);

  useDeepCompareEffect(() => {
    if (
      displayState.displayAd &&
      adUnitPath &&
      !displayState.companionAd &&
      !isFullScreen &&
      inView
    ) {
      GoogleAds.receiveTargeting(targeting);
      GoogleAds.addSlot(adUnitPath, NAV_AD_SIZES[0], 'nav', {
        ccrpos: '2000',
      });

      return () => {
        GoogleAds.removeSlot('nav');
      };
    }
  }, [displayState, adUnitPath, targeting, isFullScreen, inView]);

  /* 
  Below code is added to capture the ad click event
  As advert is rendering in the iframe, we can't capture the click event. `blur` event fired when element/window loses focus 
  (on iframe click or link opens in new tab/window), we are capturing that plus we are making sure that active element was advert. 
  If the clicked element is advert then we are firing click event to advert container so that we can do any action(ex: firing analytics event). 
  We are ensuring `blur` event *will* fire by focusing window with `window.focus()`. Throttle is added to prevent multiple taps/clicks.
  */
  useEffect(() => {
    const thisHandler = handler({ containerId: 'nav' });

    window.focus();
    window.addEventListener('blur', thisHandler);

    return () => {
      window.removeEventListener('blur', thisHandler);
    };
  }, []);

  useEffect(() => {
    if (
      isNotBlank(playerAdsState.current) &&
      isNotBlank(playerAdsState.current.companions)
    ) {
      const companionToRender =
        playerAdsState.current.companions.find(companion => {
          return (
            companion.height === NAV_AD_SIZES[0][1] &&
            companion.width === NAV_AD_SIZES[0][0]
          );
        }) ?? null;
      if (companionToRender && !isFullScreen) {
        setCompanion(<CompanionAd companion={companionToRender} />);
        setDisplayState({
          displayAd: false,
          companionAd: true,
        });
      } else {
        setCompanion(null);
        setDisplayState(previousProps => ({
          ...previousProps,
          companionAd: false,
        }));
      }
    } else {
      setCompanion(null);
      setDisplayState(previousProps => ({
        ...previousProps,
        companionAd: false,
      }));
    }
  }, [playerAdsState.current, isFullScreen]);

  return displayAdsEnabled ?
      <NavAdContainer
        data-test={
          displayState.companionAd ? 'nav-ad-companion' : 'nav-ad-slot'
        }
        data-type={displayState.companionAd ? 'companion' : 'ad'}
        height={NAV_AD_SIZES[0][1]}
        id={displayState.companionAd ? 'nav-companion' : 'nav'}
        onClick={displayState.displayAd ? adClickHandler : undefined}
        ref={ref}
        width={NAV_AD_SIZES[0][0]}
      >
        {displayState.companionAd ? companion : null}
      </NavAdContainer>
    : null;
});
