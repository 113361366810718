import type {
  ampContract,
  ClientInferResponseBody,
} from '@iheartradio/web.api/amp';
import { getLiveAdUnit, Playback } from '@iheartradio/web.playback';
import { memoize } from '@iheartradio/web.utilities';
import { useMemo, useRef } from 'react';

import { useConfig } from '~app/contexts/config';
import { playback } from '~app/playback/playback';

const getPrimaryMarket = memoize(
  (
    markets?: ClientInferResponseBody<
      typeof ampContract.v3.livemeta.getStationMeta
    >['markets'],
  ) => {
    if (!markets) {
      return '';
    }

    const market = markets.find(m => m.isPrimary);

    if (market?.name === 'DIGITAL-NAT') {
      return 'DIGITALCHANNELS';
    }

    return market?.name ?? '';
  },
);

const getPostfix = memoize((_stationId?: string | number) => {
  return Math.random() > 0.5 ? 'n' : undefined;
});

export const useAdUnit = () => {
  const config = useRef(useConfig());

  const playerState = playback.useState();
  const playerAdsState = playback.useAds();

  const adId = useRef(config.current.ads.dfpInstanceId);
  const defaultAdUnit = useRef(`/${adId.current}/ccr.ihr/ihr4`);

  return useMemo(() => {
    const queue = playerState.queue;
    const index = playerState.index;

    const stationType = playerState.station?.type;
    const stationId = playerState.station?.id;

    const itemMeta = queue?.[index]?.meta as Playback.QueueItem['meta'];
    const primaryMarket = getPrimaryMarket(itemMeta?.markets);

    const callLetters = itemMeta?.callLetters;
    const audioProvider = itemMeta?.provider;

    if (
      ((playerAdsState.status === Playback.AdPlayerStatus.Idle &&
        playerState.status === Playback.Status.Idle) ||
        playerAdsState.status === Playback.AdPlayerStatus.Done) &&
      playerState.status !== Playback.Status.Buffering
    ) {
      const isPlaying = playerState.status === Playback.Status.Playing;

      if (!isPlaying) {
        return defaultAdUnit.current;
      } else if (stationType?.toLowerCase() !== Playback.StationType.Live) {
        const postfix = getPostfix(
          Array.isArray(stationId) ? stationId[0] : stationId,
        );

        return `/${[
          adId.current,
          ['ccr.ihr', postfix].filter(Boolean).join('.'),
          'customtalk',
        ]
          .filter(Boolean)
          .join('/')}`;
      } else {
        return (
          getLiveAdUnit({
            provider: audioProvider,
            callLetters,
            market: primaryMarket,
            dfpInstanceId: adId.current,
          }) ?? defaultAdUnit.current
        );
      }
    } else {
      return null;
    }
  }, [
    playerAdsState.status,
    playerState.status,
    playerState.queue,
    playerState.index,
    playerState.station?.id,
    playerState.station?.type,
  ]);
};
